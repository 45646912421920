import React, {useContext, useEffect, useState} from "react";

import "./messages-page.scss";
import {AuthContext} from "../../shared/ui/auth-context/auth-context";
import {Button, Divider, Modal, Space, Spin, Table, TableProps, Tag, Typography} from "antd";
import {IconChecklist, IconMessageCircleQuestion} from "@tabler/icons-react";
import {useNavigate} from "react-router-dom";
import Paragraph from "antd/lib/typography/Paragraph";
const { Text } = Typography;

interface DataType {
    index: number;
    theme: string;
    question: string;
    response?: string;
    status: string;
}

export const MessagesPage = (): JSX.Element => {
    const authContext = useContext(AuthContext);

    useEffect(() => {
    });

    const showRequestModal = (message: DataType) => {
        Modal.info({
            title: 'Ваш вопрос',
            content: (
                <Text>{message.question}</Text>
            ),
            onOk() {},
        });
    };

    const showResponseModal = (message: DataType) => {
        Modal.info({
            title: 'Ответ на ваш вопрос',
            content: (
                <Text>{message.response}</Text>
            ),
            onOk() {},
        });
    };

    const columns: TableProps<DataType>['columns'] = [
        {
            title: 'Номер вопроса',
            dataIndex: 'index',
            key: 'index',
            width: 15,
            align: 'center',
            render: (text) => <span>{parseInt(text) + 1}</span>
        },
        {
            title: 'Тема',
            dataIndex: 'theme',
            key: 'theme',
            align: 'center'
        },
        {
            title: 'Содержание вопроса',
            dataIndex: 'question',
            key: 'question',
            align: 'center'
        },
        {
            title: 'Статус',
            key: 'status',
            dataIndex: 'status',
            align: 'center',
            render: (_, { status }) =>
                <Tag color={status === 'Ответ готов' ? "green" : "yellow"} key={status}>{status}</Tag>,
        },
        {
            title: 'Доступные действия',
            key: 'action',
            align: 'center',
            render: (_, record) => (
                record.response ? <Space size="small">
                    <a
                        onClick={() => {showRequestModal(record)}}
                    >
                        Вопрос
                    </a> <a
                    onClick={() => {showResponseModal(record)}}
                >
                    Ответ
                </a>
                </Space> : <a
                    onClick={() => {showRequestModal(record)}}
                >
                    Вопрос
                </a>
            ),
        },
    ];

    const data: DataType[] = [
        {
            index: 0,
            theme: 'Простой вопрос',
            question: 'ЭЛАП - что-то про лапки?',
            response: 'Нет :( Это про академическое пиьсмо..',
            status: "Ответ готов",
        },
        {
            index: 1,
            theme: 'Вопрос в процессе',
            question: 'Тут просто так не разобраться..',
            status: 'Ответ в процессе'
        }
    ];

    return (
        <div className="messages-page">
            {authContext ? (
                    <>
                        <div className="welcome-text">
                            <Paragraph>
                                <strong>Дорогой студент</strong>,<br />
                                Перед тем, как отправить вопрос Вашему ментору, ознакомьтесь с
                                <a href="#"> правилами работы</a> модуля «Вопросы ментору».<br />
                            </Paragraph>
                            <Paragraph className="signature" italic>
                                С уважением,<br />команда проекта «ЭЛАП»
                            </Paragraph>
                        </div>
                        <Divider />
                        <Table columns={columns} pagination={{pageSize: 6}} dataSource={data}
                               style={{width: "1000px", marginTop: "20px"}} bordered />
                        <Button type="primary" shape="round" htmlType="submit" style={{marginTop: "20px", width: "300px"}}>
                            Задать вопрос ментору
                        </Button>
                    </>
                ) :
                <Spin tip="Загрузка" size="large" spinning={!authContext} />}
        </div>
    )
};
