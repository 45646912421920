import React, {useContext} from "react";

import "./learn-expert-page.scss";
import {Section} from "../../shared/ui/section/section";
import {Avatar, Card, Col, Empty, Image, Row, Spin, Typography} from "antd";
import HeroImg from "../../hero.webp";
import {AuthContext} from "../../shared/ui/auth-context/auth-context";

const { Paragraph } = Typography;

export const LearnExpertPage = (): JSX.Element => {
    const authContext = useContext(AuthContext);

    return (
        <div className="learn-expert-page">
            {authContext ? <Empty description="Пока у вас нет доступных заданий!" /> :
                <Spin tip="Загрузка" size="large" spinning={!authContext} />}
        </div>
    )
};
