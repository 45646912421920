import React from "react";
import {Card, Col, Collapse, CollapseProps, Flex, Image, Row, Typography} from 'antd';
import HeroImg from 'hero.webp';

import "./main-page.scss";
import {Section} from "../../shared/ui/section/section";

const {Paragraph } = Typography;

const items: CollapseProps['items'] = [
  {
    key: '1',
    label: 'Вопрос 1',
    children: <p>Если бы мы знали, но мы не знаем..</p>,
  },
  {
    key: '2',
    label: 'Вопрос 2',
    children: <p>Если бы мы знали, но мы не знаем..</p>,
  },
  {
    key: '3',
    label: 'Вопрос 3',
    children: <p>Если бы мы знали, но мы не знаем..</p>,
  },
];

export const MainPage = (): JSX.Element => {
  return <div className="main-page">
    <div className="hero-section">
      <div className="hero-text">
        <Paragraph style={{fontSize: "21pt", fontWeight: "500", marginRight: "480px"}} className="hero-text-row">
          Электронная
        </Paragraph>
        <Paragraph style={{fontSize: "42pt", fontWeight: "500"}} className="hero-text-row">
          ЛАБОРАТОРИЯ
        </Paragraph>
        <Paragraph style={{fontSize: "48pt", fontWeight: "300"}} className="hero-text-row" italic>
          академического
        </Paragraph>
        <Paragraph style={{fontSize: "48pt", fontWeight: "500", marginRight: "-480px"}} className="hero-text-row" italic>
          письма
        </Paragraph>
      </div>
      <Image
          width={550}
          preview={false}
          src={HeroImg}
          style={{backgroundColor: "#f6f6f6", borderRadius: "32px"}}
      />
    </div>
    <Section title="Объявления" additionalUrl="/advertisements" additionalText="Посмотреть все">
      <Row gutter={16}>
        <Col span={8}>
          <Card style={{backgroundColor: "#d8b2ed"}} bordered={false}>
            <Paragraph style={{fontSize: "12pt", fontWeight: "bold"}}>
              Начало курса 1 сентбря!
            </Paragraph>
            <Paragraph>
              Чтобы записаться, вам нужно заполнить анкету на сайте УРФУ.  За более подробной
              информацией вы можете обратиться к Иванченко Дмитрию.
            </Paragraph>
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{backgroundColor: "#d8b2ed"}} bordered={false}>
            <Paragraph style={{fontSize: "12pt", fontWeight: "bold"}}>
              Начало курса 1 сентбря!
            </Paragraph>
            <Paragraph>
              Чтобы записаться, вам нужно заполнить анкету на сайте УРФУ.  За более подробной
              информацией вы можете обратиться к Иванченко Дмитрию.
            </Paragraph>
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{backgroundColor: "#d8b2ed"}} bordered={false}>
            <Paragraph style={{fontSize: "12pt", fontWeight: "bold"}}>
              Начало курса 1 сентбря!
            </Paragraph>
            <Paragraph>
              Чтобы записаться, вам нужно заполнить анкету на сайте УРФУ.  За более подробной
              информацией вы можете обратиться к Иванченко Дмитрию.
            </Paragraph>
          </Card>
        </Col>
      </Row>
    </Section>
    <br />
    <br />
    <br />
    <Section title="FAQ">
      <Collapse size="middle" accordion items={items} />
    </Section>
  </div>;
};
