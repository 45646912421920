import React, {useEffect, useState} from "react";

import "./register-page.scss";
import {useNavigate} from "react-router-dom";
import {httpClient} from "../../shared/api/http-client";
import {config} from "../../shared/config";
import {AxiosError} from "axios";
import {Button, Checkbox, Divider, Form, FormProps, Input} from "antd";

type FieldType = {
    firstName?: string;
    lastName?: string;
    patronymic?: string;
    email?: string;
    password?: string;
    confirmPassword?: string;
    confirmOPD?:boolean;
};

export const RegisterPage = (): JSX.Element => {
    const navigate = useNavigate();

    useEffect(() => {
        //
    }, []);

    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        console.log(values);
    };

    return (
        <div className="register-page">
            <Form
                name="basic"
                style={{maxWidth: "inline"}}
                initialValues={{remember: true}}
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item<FieldType>
                    label="Фамилия:"
                    labelAlign="left"
                    name="lastName"
                    rules={[
                        {required: true, message: 'Введите фамилию!'},
                        {required: true, message: 'Введите фамилию!'},
                    ]}
                >
                    <Input placeholder="Введите фамилию.." style={{borderRadius: "32px"}}/>
                </Form.Item>
                <Form.Item<FieldType>
                    label="Имя:"
                    labelAlign="left"
                    name="firstName"
                    rules={[{required: true, message: 'Введите имя!'}]}
                >
                    <Input placeholder="Введите имя.." style={{borderRadius: "32px"}}/>
                </Form.Item>
                <Form.Item<FieldType>
                    label="Отчество:"
                    labelAlign="left"
                    name="patronymic"
                >
                    <Input placeholder="Введите отчество (если оно есть).." style={{borderRadius: "32px"}}/>
                </Form.Item>

                <Form.Item<FieldType>
                    label="E-mail:"
                    labelAlign="left"
                    tooltip="Название Вашей учетной записи, которая привязана к Вашему личному кабинету на сайте УрФУ"
                    name="email"
                    rules={[{required: true, message: 'Введите почту!'}]}
                >
                    <Input addonAfter="@urfu.ru" placeholder="Введите почту.."/>
                </Form.Item>

                <Form.Item<FieldType>
                    label="Пароль:"
                    tooltip="Пароль должен содержать минимум 8 символов"
                    name="password"
                    rules={[
                        {required: true, message: 'Введите пароль!'},
                        {min: 8, message: 'Пароль должен содержать минимум 8 символов!'}
                    ]}
                >
                    <Input.Password style={{borderRadius: "32px"}} placeholder="Введите пароль.."/>
                </Form.Item>

                <Form.Item<FieldType>
                    label="Повторите пароль:"
                    name="confirmPassword"
                    rules={[
                        {required: true, message: 'Введите пароль повторно!'},
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Пароли не совпадают!'));
                            },
                        })]}
                >
                    <Input.Password style={{borderRadius: "32px"}} placeholder="Введите пароль повторно.."/>
                </Form.Item>

                <Form.Item
                    name="confirmOPD"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject(new Error('Соглашение должно быть принято!')),
                        },
                    ]}
                >
                    <Checkbox>
                        Я согласен на <a href="#">обработку персональных данных</a>.
                    </Checkbox>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" shape="round" htmlType="submit" style={{marginTop: "10px", width: "300px"}}>
                        Зарегистрироваться
                    </Button>
                </Form.Item>
            </Form>
            <Divider plain>или</Divider>
            <Button type="primary" shape="round" style={{width: "300px"}} onClick={() => navigate("/login")}>
                Войти
            </Button>
        </div>
    );
};
