import React, {useContext} from "react";

import "./videos-page.scss";
import {Section} from "../../shared/ui/section/section";
import {Avatar, Card, Col, Empty, Image, Row, Spin, Typography} from "antd";
import HeroImg from "../../hero.webp";
import {AuthContext} from "../../shared/ui/auth-context/auth-context";

const { Paragraph } = Typography;

export const VideosPage = (): JSX.Element => {
    const authContext = useContext(AuthContext);

    return (
        <div className="videos-page">
            {authContext ? <Empty description="Еще нет ни одного загруженного видео" /> :
                <Spin tip="Загрузка" size="large" spinning={!authContext} />}
        </div>
    )
};
