import React, { createContext, ReactNode, useEffect, useState } from "react";

import { config } from "shared/config";

import { httpClient } from "shared/api/http-client";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import {UserInfo} from "../../model/user-info";
import {UserRole} from "../../model/user-role";

type AuthContext = {
  userInfo: UserInfo;
} | null;

export const authContextDefaultValue: AuthContext = null;

export const AuthContext = createContext<AuthContext>(authContextDefaultValue);

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const [authContext, setAuthContext] = useState<AuthContext>(
    authContextDefaultValue
  );

  useEffect(() => {
    const cachedUserInfo = localStorage.getItem("elap:portal:user");
    if (cachedUserInfo) {
      const userInfo: UserInfo = JSON.parse(cachedUserInfo);
      if (userInfo) {
        setAuthContext({userInfo});
      } else {
        localStorage.removeItem("elap:portal:auth");
        localStorage.removeItem("elap:portal:user");
        navigate("/login");
      }
    } else {
      navigate("/login");
/*      httpClient
          .axios()
          .get<UserInfo>(config.endPoints.getUserInfoUrl)
          .then((response) => {
            setAuthContext({userInfo: response.data});
            localStorage.setItem("elap:portal:user", JSON.stringify(response.data));
          })
          .catch((error: AxiosError) => {
            if (error.status === 401) {
              localStorage.removeItem("elap:portal:auth");
              localStorage.removeItem("elap:portal:user");
              navigate("/login");
            } else {
              navigate("/error");
            }
          });*/
/*      setAuthContext({userInfo: {
          firstName: "Иван",
          lastName: "Иванов",
          patronymic: "Иванович",
          email: "test@urfu.ru",
          userRoles: []}
      });
      localStorage.setItem("elap:portal:user", JSON.stringify({
        firstName: "Иван",
        lastName: "Иванов",
        patronymic: "Иванович",
        email: "test@urfu.ru",
        userRoles: []}));*/
    }
  }, []);

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};
