import React from "react";

import "./about-page.scss";
import {Section} from "../../shared/ui/section/section";
import {Avatar, Card, Col, Image, Row, Typography} from "antd";
import HeroImg from "../../hero.webp";

const { Paragraph } = Typography;

export const AboutPage = (): JSX.Element => {

    return (
        <div className="about-page">
            <div style={{textAlign: "center"}}>
                <Paragraph style={{fontSize: "18pt"}}>Электронная лаборатория Академического письма</Paragraph>
            </div>
            <Section title="Цель и миссия проекта">
                <Paragraph>
                    Lorem ipsum odor amet, consectetuer adipiscing elit. Egestas magna quis vestibulum malesuada egestas porttitor penatibus duis. Iaculis scelerisque ac nostra dictumst auctor nunc risus facilisis. Morbi a rutrum, donec condimentum phasellus laoreet dignissim. Mauris euismod consequat tempus ex tincidunt. Purus porta senectus phasellus nullam lobortis. Hac vestibulum sodales eget primis condimentum. Aliquam sociosqu faucibus dignissim eget at etiam. Himenaeos gravida nisl suscipit taciti tempus per parturient. Ultrices luctus vulputate fermentum vulputate massa vulputate.
                </Paragraph>
                <Paragraph>
                    Purus bibendum lectus finibus quis a curabitur ultricies lacus. Quis risus placerat viverra leo et natoque odio fames nisl. Fermentum quis elit ad inceptos ante. Nisi morbi molestie malesuada praesent; varius litora sed purus. Litora dictum duis orci imperdiet aliquam nascetur. Vestibulum ultrices varius, pulvinar accumsan suspendisse auctor elit. Eleifend nisl lectus ad tristique cubilia netus. Dui fermentum consequat senectus dictum ac ultricies facilisis. Class ullamcorper himenaeos tristique etiam vehicula velit.
                </Paragraph>
                <Paragraph>
                    Hendrerit condimentum congue phasellus dis convallis parturient justo. Morbi nulla congue diam et senectus. Morbi conubia at dignissim euismod amet. Metus nunc posuere ultrices tristique vulputate et sem. Augue congue conubia non placerat iaculis conubia ad metus eros. Conubia cras condimentum a posuere sociosqu eget. Scelerisque dictum fringilla elit hac enim. Tortor metus luctus augue elit; aliquet eget dictum rutrum.
                </Paragraph>
                <div style={{width: "100%", height: "auto", display: "flex", justifyContent: "center", flexDirection: "row"}}>
                    <Image
                        width={600}
                        preview={false}
                        src="https://inbusiness.kz/uploads/34/images/EBZtk2RH.jpg"
                        style={{borderRadius: "32px", marginBottom: "10px"}}
                    />
                </div>
                <Paragraph>
                    Convallis mattis ultrices laoreet nullam pretium habitant faucibus. Rhoncus ex conubia felis sapien tincidunt massa maecenas curabitur. Ullamcorper massa penatibus eleifend molestie ante enim faucibus ante. Himenaeos cubilia posuere nostra pharetra efficitur ullamcorper maximus eros. Suspendisse cras taciti mattis suspendisse vehicula pulvinar. Suscipit tristique donec dictumst vitae placerat morbi ad facilisi. Consectetur blandit elit ut blandit cras vulputate. Laoreet id senectus praesent molestie mollis sed a. Natoque malesuada nostra maximus magnis suscipit, consequat non eros laoreet. Fringilla interdum dignissim a est sodales ipsum sit lacus.
                </Paragraph>
            </Section>
            <br />
            <Section title="Команда проекта">
                <Row gutter={16}>
                    <Col span={12}>
                        <Card>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "50px"}}>
                                <Image
                                    width={270}
                                    height={270}
                                    preview={false}
                                    src="https://cdn-icons-png.flaticon.com/512/2387/2387568.png"
                                    style={{borderRadius: "50%"}}
                                />
                                <div style={{display: "flex", flexDirection: "column", justifyContent: "left",
                                    gap: "5px", maxWidth: "50%", textAlign: "left"}}>
                                    <Paragraph><strong>Иванов Иван иванович</strong></Paragraph>
                                    <Paragraph>Участник</Paragraph>
                                    <Paragraph>
                                        Lorem ipsum odor amet, consectetuer adipiscing elit. Egestas magna quis vestibulum malesuada egestas porttitor penatibus duis. Iaculis scelerisque ac nostra dictumst auctor nunc risus facilisis. Morbi a rutrum, donec condimentum phasellus laoreet dignissim. Mauris euismod consequat tempus ex tincidunt. Purus porta senectus phasellus nullam lobortis. Hac vestibulum sodales eget primis condimentum. Aliquam sociosqu faucibus dignissim eget at etiam. Himenaeos gravida nisl suscipit taciti tempus per parturient. Ultrices luctus vulputate fermentum vulputate massa vulputate.
                                    </Paragraph>
                                    <a href="#">Telegram</a>
                                    <a href="#">VK</a>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "50px"}}>
                                <Image
                                    width={270}
                                    height={270}
                                    preview={false}
                                    src="https://cdn-icons-png.flaticon.com/512/2387/2387568.png"
                                    style={{borderRadius: "50%"}}
                                />
                                <div style={{display: "flex", flexDirection: "column", justifyContent: "left",
                                    gap: "5px", maxWidth: "50%", textAlign: "left"}}>
                                    <Paragraph><strong>Иванов Иван иванович</strong></Paragraph>
                                    <Paragraph>Участник</Paragraph>
                                    <Paragraph>
                                        Lorem ipsum odor amet, consectetuer adipiscing elit. Egestas magna quis vestibulum malesuada egestas porttitor penatibus duis. Iaculis scelerisque ac nostra dictumst auctor nunc risus facilisis. Morbi a rutrum, donec condimentum phasellus laoreet dignissim. Mauris euismod consequat tempus ex tincidunt. Purus porta senectus phasellus nullam lobortis. Hac vestibulum sodales eget primis condimentum. Aliquam sociosqu faucibus dignissim eget at etiam. Himenaeos gravida nisl suscipit taciti tempus per parturient. Ultrices luctus vulputate fermentum vulputate massa vulputate.
                                    </Paragraph>
                                    <a href="#">Telegram</a>
                                    <a href="#">VK</a>
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Card>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "50px"}}>
                                <Image
                                    width={270}
                                    height={270}
                                    preview={false}
                                    src="https://cdn-icons-png.flaticon.com/512/2387/2387568.png"
                                    style={{borderRadius: "50%"}}
                                />
                                <div style={{display: "flex", flexDirection: "column", justifyContent: "left",
                                    gap: "5px", maxWidth: "50%", textAlign: "left"}}>
                                    <Paragraph><strong>Иванов Иван иванович</strong></Paragraph>
                                    <Paragraph>Участник</Paragraph>
                                    <Paragraph>
                                        Lorem ipsum odor amet, consectetuer adipiscing elit. Egestas magna quis vestibulum malesuada egestas porttitor penatibus duis. Iaculis scelerisque ac nostra dictumst auctor nunc risus facilisis. Morbi a rutrum, donec condimentum phasellus laoreet dignissim. Mauris euismod consequat tempus ex tincidunt. Purus porta senectus phasellus nullam lobortis. Hac vestibulum sodales eget primis condimentum. Aliquam sociosqu faucibus dignissim eget at etiam. Himenaeos gravida nisl suscipit taciti tempus per parturient. Ultrices luctus vulputate fermentum vulputate massa vulputate.
                                    </Paragraph>
                                    <a href="#">Telegram</a>
                                    <a href="#">VK</a>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "50px"}}>
                                <Image
                                    width={270}
                                    height={270}
                                    preview={false}
                                    src="https://cdn-icons-png.flaticon.com/512/2387/2387568.png"
                                    style={{borderRadius: "50%"}}
                                />
                                <div style={{display: "flex", flexDirection: "column", justifyContent: "left",
                                    gap: "5px", maxWidth: "50%", textAlign: "left"}}>
                                    <Paragraph><strong>Иванов Иван иванович</strong></Paragraph>
                                    <Paragraph>Участник</Paragraph>
                                    <Paragraph>
                                        Lorem ipsum odor amet, consectetuer adipiscing elit. Egestas magna quis vestibulum malesuada egestas porttitor penatibus duis. Iaculis scelerisque ac nostra dictumst auctor nunc risus facilisis. Morbi a rutrum, donec condimentum phasellus laoreet dignissim. Mauris euismod consequat tempus ex tincidunt. Purus porta senectus phasellus nullam lobortis. Hac vestibulum sodales eget primis condimentum. Aliquam sociosqu faucibus dignissim eget at etiam. Himenaeos gravida nisl suscipit taciti tempus per parturient. Ultrices luctus vulputate fermentum vulputate massa vulputate.
                                    </Paragraph>
                                    <a href="#">Telegram</a>
                                    <a href="#">VK</a>
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Section>
            <br />
            <br />
        </div>
    )
};
