type Config = {
  baseApiUrl: string;
  endPoints: {
    getUserInfoUrl: string;
    loginUrl: string;
  };
};

export const config: Config = {
  baseApiUrl: "/api/ui",
  endPoints: {
    getUserInfoUrl: "/userInfo",
    loginUrl: "/login"
  },
};
