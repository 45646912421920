import React, {useEffect} from "react";

export const LogoutPage = (): JSX.Element => {
  useEffect(() => {
    localStorage.removeItem("elap:portal:auth");
    localStorage.removeItem("elap:portal:user");
    window.location.replace("/");
  }, []);

  return (<></>);
};
