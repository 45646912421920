import {
  IconBellRinging,
  IconBookmark,
  IconChefHat,
  IconHome,
  IconInfoCircle,
  IconLogout,
  IconMessageCircle,
  IconPaperclip, IconScript,
  IconSettings, IconUser,
  IconVideo,
} from "@tabler/icons-react";
import { Button, Flex, Layout, Menu, theme, Tooltip } from "antd";
import React, {useEffect, useState} from "react";
import "./custom-layout.scss";
import { useLocation, useNavigate } from "react-router-dom";
import {UserInfo} from "../../model/user-info";

const { Header, Content, Sider } = Layout;

type NavItem = {
  key: string;
  title: string;
  url: string;
  icon: React.ReactNode;
};

const navItems: NavItem[] = [
  {
    key: "0",
    title: "Главная",
    url: "/",
    icon: <IconHome />
  },
  {
    key: "1",
    title: "Обучение",
    url: "/learn/",
    icon: <IconBookmark />
  },
  {
    key: "2",
    title: "О проекте",
    url: "/about",
    icon: <IconInfoCircle />
  },
  {
    key: "3",
    title: "Видеосюжеты",
    url: "/videos",
    icon: <IconVideo />
  },
];

export const CustomLayout = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  useEffect(() => {
    const cachedUserInfo = localStorage.getItem("elap:portal:user");
    if (cachedUserInfo) {
      const userInfo: UserInfo = JSON.parse(cachedUserInfo);
      if (userInfo) {
        setUserInfo(userInfo);
      }
    }
  }, []);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const getNavItemsRender = () => {
    const result: React.ReactNode[] = [];

    navItems.forEach((item) => {
      result.push(
          <Menu.Item
              key={item.key}
              icon={item.icon}
              onClick={() => {
                navigate(item.url);
              }}
          >
            {item.title}
          </Menu.Item>
      );
    });

    return result;
  };

  const getActiveNavItemKey = (): string => {
    let result: string = "";
    const activePage = navItems.filter(
        (item) => item.url === location.pathname
    )[0];
    if (activePage) {
      result = activePage.key;
    }
    return result;
  };

  return (
      <Layout style={{ height: "100vh" }}>
        <Sider width={200}>
          <Flex justify="center" align="center">
            <h1>ЭЛАП</h1>
          </Flex>

          <Menu mode="inline" selectedKeys={[getActiveNavItemKey()]}>
            {getNavItemsRender()}
          </Menu>
        </Sider>
        <Layout>
          <Header
              style={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
              }}
          >
            <Flex justify={"flex-end"} align={"center"} gap={15}>
              {userInfo ? <span className="header-username">{userInfo.firstName}</span> : <Button
                  shape="round"
                  type="primary"
                  size="large"
                  onClick={() => {
                    navigate("/login");
                  }}
              >
                Войти
              </Button>}
              {userInfo ?  <Tooltip title="Личный кабинет" placement="bottom">
                <Button
                    shape="circle"
                    icon={<IconUser />}
                    size="large"
                    onClick={() => {
                      navigate("/account");
                    }}
                />
              </Tooltip> : null}
              {userInfo ?  <Tooltip title="Выйти" placement="bottom">
                <Button
                    shape="circle"
                    icon={<IconLogout />}
                    size="large"
                    onClick={() => {
                      navigate("/logout");
                    }}
                />
              </Tooltip> : null}
            </Flex>
          </Header>
          <Content
              style={{
                borderTopLeftRadius: borderRadiusLG,
                backgroundColor: colorBgContainer,
                padding: "16px",
                overflow: "auto",
                height: "100%"
              }}
          >
            {children}
          </Content>
          {/* <Footer style={{ textAlign: "center" }}>
          ЭЛАП ©{new Date().getFullYear()} г.
        </Footer> */}
        </Layout>
      </Layout>
  );
};
