import React, {useContext} from "react";
import {Card, Col, Collapse, CollapseProps, Empty, Flex, Image, Row, Spin, Typography} from 'antd';
import HeroImg from 'hero.webp';

import "./learn-page.scss";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../shared/ui/auth-context/auth-context";

const {Paragraph} = Typography;

export const LearnPage = (): JSX.Element => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  return <div className="learn-page">
    {authContext ? (
            <>
              <Row gutter={16}>
                <Col span={8}>
                  <Card style={{backgroundColor: "#d8b2ed", cursor: "pointer", height: "100px", width: "300px",
                    display: "flex", justifyContent: "center", alignItems: "center"}} bordered={false} onClick={() => {
                    navigate("/learn/expert");
                  }}>
                    Русский язык для научного стиля
                  </Card>
                </Col>
                <Col span={8}>
                  <Card style={{backgroundColor: "#d8b2ed", cursor: "pointer", height: "100px", width: "300px",
                    display: "flex", justifyContent: "center", alignItems: "center"}} bordered={false} onClick={() => {
                    navigate("/learn/expert");
                  }}>
                    Плагиат и включение чужих работ в свой текст
                  </Card>
                </Col>
                <Col span={8}>
                  <Card style={{backgroundColor: "#d8b2ed", cursor: "pointer", height: "100px", width: "300px",
                    display: "flex", justifyContent: "center", alignItems: "center"}} bordered={false} onClick={() => {
                    navigate("/learn/expert");
                  }}>
                    Аргументация и логика
                  </Card>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Card style={{backgroundColor: "#d8b2ed", cursor: "pointer", height: "100px", width: "300px",
                    display: "flex", justifyContent: "center", alignItems: "center"}} bordered={false} onClick={() => {
                    navigate("/learn/expert");
                  }}>
                    Жанры академических текстов
                  </Card>
                </Col>
                <Col span={8}>
                  <Card style={{backgroundColor: "#d8b2ed", cursor: "pointer", height: "100px", width: "300px",
                    display: "flex", justifyContent: "center", alignItems: "center"}} bordered={false} onClick={() => {
                    navigate("/learn/expert");
                  }}>
                    Магистерская диссертация
                  </Card>
                </Col>
                <Col span={8}>
                  <Card style={{backgroundColor: "#d8b2ed", cursor: "pointer", height: "100px", width: "300px",
                    display: "flex", justifyContent: "center", alignItems: "center"}} bordered={false} onClick={() => {
                    navigate("/learn/expert");
                  }}>
                    Цифровые инструменты
                  </Card>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Card style={{backgroundColor: "#d8b2ed", cursor: "pointer", height: "100px", width: "300px",
                    display: "flex", justifyContent: "center", alignItems: "center"}} bordered={false} onClick={() => {
                    navigate("/learn/expert");
                  }}>
                    Полезные ресурсы
                  </Card>
                </Col>
                <Col span={12}>
                  <Card style={{backgroundColor: "#d8b2ed", cursor: "pointer", height: "100px", width: "300px",
                    display: "flex", justifyContent: "center", alignItems: "center"}} bordered={false} onClick={() => {
                    navigate("/learn/expert");
                  }}>
                    Экспертно-редакторская кухня
                  </Card>
                </Col>
              </Row>
            </>
        ) :
        <Spin tip="Загрузка" size="large" spinning={!authContext} />}
  </div>;
};
