import React, {useContext, useEffect} from "react";

import "./account-page.scss";
import {AuthContext} from "../../shared/ui/auth-context/auth-context";
import {Button, Empty, Form, Input, Spin} from "antd";
import {IconChecklist, IconMessageCircleQuestion} from "@tabler/icons-react";
import {useNavigate} from "react-router-dom";

export const AccountPage = (): JSX.Element => {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const userFullName = `${authContext?.userInfo.lastName} `
        + `${authContext?.userInfo.firstName} ${authContext?.userInfo.patronymic}`;

    useEffect(() => {
        console.log(authContext);
    });

    return (
        <div className="account-page">
            {authContext ? (
                    <>
                        <Form
                            name="basic"
                            style={{ maxWidth: "inline" }}
                            initialValues={{ remember: true }}
                            autoComplete="off"
                            layout="vertical"
                        >
                            <Form.Item
                                label="ФИО:"
                                labelAlign="left"
                            >
                                <Input value={userFullName} disabled style={{borderRadius: "32px", width: "650px"}} />
                            </Form.Item>

                            <Form.Item
                                label="Группа:"
                                labelAlign="left"
                            >
                                <Input value=".." disabled style={{borderRadius: "32px"}} />
                            </Form.Item>

                            <Form.Item
                                label="Ваш ментор:"
                                labelAlign="left"
                            >
                                <Input value=".." disabled style={{borderRadius: "32px"}} />
                            </Form.Item>
                        </Form>
                        <div className="actions-block">
                            <Button type="primary" shape="round" icon={<IconMessageCircleQuestion />} size="large"
                            onClick={() => navigate("/messages")}>
                                Задать вопрос ментору
                            </Button>
                            <Button type="primary" shape="round" icon={<IconChecklist />} size="large"
                                    onClick={() => navigate("/learn/expert")}>
                                Посмотреть текущие задания
                            </Button>
                        </div>
                    </>
                ) :
                <Spin tip="Загрузка" size="large" spinning={!authContext} />}
        </div>
    )
};
